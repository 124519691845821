
import { Options, Vue } from "vue-class-component";
import AppFooter from "@/components/AppFooter.vue";
import AppNav from "@/components/AppNav.vue";

@Options({
    components: {
        AppFooter,
        AppNav,
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
            let HTMLBody = document.getElementsByTagName(
                "html"
            )[0] as NonNullable<HTMLElement>;
            HTMLBody.style.overflowY = 'auto'
        }, 1000);
    },
    data() {
        return {
            isLoading: true,
        };
    },
})
export default class App extends Vue {}
