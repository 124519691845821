import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Inicio.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Inicio",
        component: Home,
    },
    {
        path: "/noticias",
        name: "Noticias",
        component: () => import("../views/Noticias.vue"),
    },
    {
        path: "/noticias/municipio",
        name: "Municipio",
        component: () => import("../views/Municipio.vue"),
    },
    {
        path: "/transparencia",
        name: "Transparencia",
        component: () => import("../views/Transparencia.vue"),
    },
    {
        path: "/contacto",
        name: "Contacto",
        component: () => import("../views/Contacto.vue"),
    },
    {
        path: "/covid-19",
        name: "COVID-19",
        component: () => import("../views/COVID-19.vue"),
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView();
  }
});

export default router;
