import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//global styles
import "@/assets/styles/styles.scss"
//masonry
import MasonryWall from '@yeger/vue-masonry-wall';

const app = createApp(App);
app.use(store).use(router).mount("#app");
app.use(MasonryWall);


