
import { defineComponent } from "vue";
import SocialMedia from "@/components/SocialMedia.vue";
// import WelcomeMessage from '@/components/WelcomeMessage.vue'

export default defineComponent({
    components: {
        SocialMedia,
        // WelcomeMessage
    },
});
