
import { defineComponent, ref } from "vue";

export default defineComponent({
    setup() {
        const stateMenu = ref(true);
        let HTMLBody = document.getElementsByTagName(
            "html"
        )[0] as NonNullable<HTMLElement>;

        const toggleMenu = () => {
            stateMenu.value = !stateMenu.value;
        };

        const beforeEnter = () => {
            HTMLBody.style.overflow = "hidden";
        };

        const afterLeave = () => {
            HTMLBody.style.overflowY = "auto";
        };

        return {
            stateMenu,
            toggleMenu,
            HTMLBody,
            beforeEnter,
            afterLeave,
        };
    },
});
