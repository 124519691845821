
import { Options, Vue } from "vue-class-component";
import MobileMenu from "./MobileMenu.vue";

@Options({
    components: {
        MobileMenu,
    },
    mounted() {
        //#region Nav listeners
        this.mediaquery = window.matchMedia(
            "(max-width: 1300px)"
        ) as NonNullable<MediaQueryList>;
        this.navbar = document.getElementById(
            "nav"
        ) as NonNullable<HTMLElement>;
        this.navbarBottom = document.getElementById(
            "nav-bottom"
        ) as NonNullable<HTMLElement>;
        window.addEventListener("scroll", this.resizeNavbar);
        this.mediaquery.addEventListener("change", this.changeNavbarHeight);
        //#endregion
    },
    unmounted() {
        window.removeEventListener("scroll", this.resizeNavbar);
        this.mediaquery.removeEventListener("change", this.changeNavbarHeight);
    },
})
export default class AppNav extends Vue {
    mediaquery!: MediaQueryList;
    navbar!: HTMLElement;
    navbarBottom!: HTMLElement;
    bottomMessage = "San Marcos a lo grande!";

    changeNavbarHeight(mediaquery: MediaQueryList) {
        if (mediaquery.matches) {
            this.navbar.style.height = "80px";
        } else {
            this.navbar.style.height = "100px";
        }

        this.navbarBottom.style.opacity = "1";
    }

    resizeNavbar() {
        if (window.scrollY > 150) {
            this.navbar.style.height = "60px";
            this.navbarBottom.style.opacity = "0";
        } else {
            this.changeNavbarHeight(this.mediaquery);
        }
    }
}
